@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");


body {
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

p {
  font-family: montserrat, sans-serif;
  font-weight: 200;
}

h3 {
  font-family: montserrat, sans-serif;
  font-weight: 600;
}

h2 {
  font-family: montserrat, sans-serif;
  font-weight: bold;
}

h1 {
  font-family: montserrat, sans-serif;
  font-weight: bold;
}

li {
  font-family: montserrat, sans-serif;
  margin-bottom: 10px;
}

hr {
  border: 1px solid black;
}

.addressText {
  margin: 0;
  text-align: center;
}

.galleryContainer {
  position: relative;
  margin-top: 2.5rem;
}

.galleryImg {
  width: 100%;
  height: auto;
}

.galleryBtn {
  font-family: montserrat, sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: transparent;
  border: 3px solid white;
  color: white;
  font-size: 1.25rem;
  padding: 0.6rem 3rem;
  cursor: pointer;
  border-radius: 5px;
}

.galleryBtn:hover {
  background-color: white;
  color: black;
}

h4 {
  text-align: center;
  font-family: montserrat, sans-serif;
}

.servicesBtn {
  background-color: transparent;
  border: 3px black solid;
  font-family: montserrat, sans-serif;
  margin-top: 40px;
  padding: 0.6rem 1.9rem;
  color: black;
}

.servicesBtn:hover {
  background-color: black;
  color: white;
}

.blueHeader {
  background-color: #52b0db;
  padding-top: 10px;
  padding-bottom: 10px;
}

.headerTitle {
  color: white;
}

.coversWrapper {
  max-width: 90%;
}

.coversContent {
  background-color: #e5e4e2;
  justify-content: center;
  margin-bottom: 2rem;
  display: flex;
}

.servicesContent {
  margin-bottom: 2rem;
}

.imageWrapper {
  margin: 0 auto;
  width: 650px;
}

.imageWrapper > img {
  float: left;
  width: 45%;
  margin: 1.66%;
}

.servicesText {
  font-family: montserrat, sans-serif;
  font-size: 1.25rem;
  font-style: bold;
}

.subtitle {
  font-family: montserrat, sans-serif;
  font-size: 25px;
  font-weight: bold;
}

@media screen and (max-width: 800px) {
  .imageWrapper {
    flex: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .imageWrapper {
    flex: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .imageWrapper {
    flex: 100%;
    max-width: 100%;
  }
}

.regularProducts {
  max-width: 80%;
  margin: auto;
}

.homeBtn {
  background-color: transparent;
  border: 3px black solid;
  color: black;
  font-size: 20px;
  font-family: montserrat, sans-serif;
  margin-top: 40px;
  padding: 10px 45px;
  margin-bottom: 25px;
}

.homeBtn:hover {
  background-color: black;
  color: white;
}

.navItem {
  font-family: montserrat, sans-serif;
  margin-left: 2rem;
  margin-right: 2rem;
}

.formRow {
  margin-bottom: 0.5rem;
}

.contactContainer {
  margin: auto;
  margin-top: 50px;
  width: 40%;
}

@media only screen and (max-width: 800px) {
  .contactContainer {
    margin: auto;
    width: 80%;
  }
}

@media only screen and (max-width: 400px) {
  .slider {
   margin-top: 20px;
   height: 50vh !important
  }

  .right-arrow {
    color: rgb(51, 53, 53) !important
  }

  .left-arrow {
    color: rgb(51, 53, 53) !important;
  }
}

.slider {
  margin-top: 40px;
  position: relative;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}



.slider-image {
  max-width: 1000px;
  height: 600px;
  border-radius: 10px;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 100px;
  font-size: 3rem;
  color: #000;
  cursor: pointer;
  user-select: none;
  z-index: 10;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 100px;
  font-size: 3rem;
  color: #000;
  cursor: pointer;
  user-select: none;
  z-index: 10;
}

.image {
  opacity: 0;
  transition-duration: 1s ease;
}

.image.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}


@media only screen and (max-width: 1200px) {
  .slider-image {
    width: 600px;
    height: 600px;
  }
  .right-arrow {
    right: 32px;
  }

  .left-arrow {
    left: 32px
  }
}

@media only screen and (max-width: 1100px) {
  .slider-image {
    width: 500px;
    height: 450px;

  }
}

@media only screen and (max-width: 600px) {
  .slider-image {
    width: 300px;
    height: 250px;

  }
}